import Vue from 'vue'
import App from './views/app.vue'
import axios from "axios";
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
window.axios = require('axios');
window.apiUrl = 'http://localhost/easy2send/public/api/v1/';
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import Vuelidate from "vuelidate";
Vue.use(Vuelidate);
//import './assets/app.scss'
Vue.use(BootstrapVue)
const app = new Vue({
    el: '#app',
    components : {
        App
    }
});
