<template>
    <div class="home-section padding-y">
        <div class="container">
            <div class="heading">
                <a href="https://www.easy2send.nl/"><img src="/images/logo.png" /></a>
            </div>
            <br/>
            <div class="tracking-wrap text-center">
                <ul id="progressbar">
                    <li id="step1" v-bind:class="{ 'active' : step >= 1}"><strong>Stap 1</strong></li>
                    <li id="step2" v-bind:class="{ 'active' : step >=2}"><strong>Stap 2</strong></li>
                    <li id="step3" v-bind:class="{ 'active' : step >=3}"><strong>Stap 3</strong></li>
                    <li id="step4" v-bind:class="{ 'active' : step >=4}"><strong>Stap 4</strong></li>
                    <li id="step5" v-bind:class="{ 'active' : step >=5}"><strong>Stap 5</strong></li>
                    <li id="step6" v-bind:class="{ 'active' : step >=6}"><strong>Stap 6</strong></li>
                </ul>
                <br/>
            </div>
            <div v-if="popupMessage">
                <b-modal id="errorModal" title="Let op! Controleer/ Vul het huisnummer in" @show="resetModal" @hidden="resetModal" @ok="handleOk" ok-only>
                    <form ref="errorForm" @submit.stop.prevent="handleSubmit">                       
                        <div class="mod-form1">
                            <div class="row form-row">
                                <div class="col-md-12 form-group" v-if="!this.errorForm.pickup.addressTemp">
                                    <label for="Length">Huisnummer Ophaaladres<span class="req">*</span></label>
                                    <input v-model="errorForm.pickup.address" type="text" name="pickup_street_address"
                                        placeholder="" class="form-control" @keyup="validate($event)" @change="updateAddress" :class="!this.validationErrors.pickupstreetaddress ? 'valid' : 'is-invalid'">
                                </div>
                                <div class="col-md-12 form-group" v-if="!this.errorForm.delivery.addressTemp">
                                    <label for="Length">Huisnummer Afleveradres<span class="req">*</span></label>
                                    <input v-model="errorForm.delivery.address" type="text" name="delivery_street_address"
                                        placeholder="" class="form-control" @keyup="validate($event)" @change="updateAddress" :class="!this.validationErrors.deliverystreetaddress ? 'valid' : 'is-invalid'">
                                </div>
                            </div>
                        </div>
                    </form>
                </b-modal>
            </div>
            <form id="app" >
                <div class="alert alert-danger" v-if="errors" role="alert">
                    <div>{{errors}}</div>
                </div>
                <div class="alert alert-warning" v-if="this.form.distance && step!=6" role="alert">
                    <div>Aantal km: {{ this.form.distance }}</div>
                </div>
                
                <section :class="step > 1 ? 'first_step' : ''">
                    <h3 class="text-center">{{ title }}</h3>
                    <div class="mod-form">
                        <div class="row form-row">
                            <div class="col-md-6 form-group">
                                <label for="Length">Van waar?<span class="req">*</span></label>
                                <input v-model="form.location_from" type="text" id="fromAddress" value=""
                                    placeholder="Straat huisnummer postcode" class="form-control" @keyup="validate($event)" :class="!this.validationErrors.location_from ? 'valid' : 'is-invalid'">
                            </div>
                            <div class="col-md-6 form-group">
                                <label for="Length">Naar waar?<span class="req">*</span></label>
                                <input v-model="form.location_to" type="text" value="" id="toAddress"
                                    placeholder="Straat huisnummer postcode" class="form-control" @keyup="validate($event)" :class="!this.validationErrors.location_to ? 'valid' : 'is-invalid'">
                            </div>
                        </div>
                    </div>
                </section>
                <section v-if="step ==2">
                    <div class="card" id="vehicle-package">
                        <article class="card-body">
                            <header class="mb-4">
                                <h4 class="card-title text-center">Aantal pakketten</h4>
                            </header>
                            <div class="row form-row">
                                <div class="col-md-6 form-group">
                                    <label for="Length">Pakketten</label>
                                    <select v-model="form.selectedPackage" @change="updateDimentions"
                                        class="form-control" id="length_1">
                                        <option v-for="n in 10" :key="n" :value="n">{{ n }}</option>
                                    </select>
                                </div>
                            </div><br/>
                            <header class="mb-4">
                                <h5 class="card-title">AFMETINGEN EN GEWICHT</h5>
                            </header>
                            <div class="row form-row package_row" v-for="(p,index) in form.selectedPackage" :key="index"
                                :id="'package_row'+index">
                                <div class="col-md-3 form-group">
                                    <label for="Length">Lengte (cm)<span class="req">*</span></label>
                                    <input type="number" class="form-control length"
                                        v-model="form.dimensions[index].length" id="length_1" @keypress.enter.prevent @keyup="validate($event)" :class="dimensionErrors[index].length ? 'is-invalid' : 'valid'">
                                </div>
                                <div class="col-md-2 form-group">
                                    <label for="Width">Breedte (cm)<span class="req">*</span></label>
                                    <input type="number" class="form-control width"
                                        v-model="form.dimensions[index].width" :key="p" id="width_1"
                                        @keypress.enter.prevent @keyup="validate($event)" :class="dimensionErrors[index].width ? 'is-invalid' : 'valid'">
                                    <p id="width_1_error_para" class="invalid-message" style="color: red"></p>
                                </div>
                                <div class="col-md-2 form-group">
                                    <label for="Height">Hoogte (cm)<span class="req">*</span></label>
                                    <input type="number" class="form-control height"
                                        v-model="form.dimensions[index].height" @keypress.enter.prevent @keyup="validate($event)" :class="dimensionErrors[index].height ? 'is-invalid' : 'valid'">
                                    <p id="height_1_error_para" class="invalid-message" style="color: red"></p>
                                </div>
                                <div class="col-md-2 form-group">
                                    <label for="Weight">Gewicht (kg)<span class="req">*</span></label>
                                    <input type="number" class="form-control weight"
                                        v-model="form.dimensions[index].weight" @keypress.enter.prevent @keyup="validate($event)" :class="dimensionErrors[index].weight ? 'is-invalid' : 'valid'">
                                    <p id="weight_1_error_para" class="invalid-message" style="color: red"></p>
                                </div>
                                <div class="col-md-2 form-group" style="margin-left: 20px;">
                                    <label for="numberPackages">Aantal<span class="req">*</span></label>
                                    <input type="number" class="form-control" v-model="form.dimensions[index].number"
                                        min="1" @keypress.enter.prevent @keyup="validate($event)" :class="dimensionErrors[index].number ? 'is-invalid' : 'valid'">
                                    <p id="numberPackages_1_error_para" style="color: red"></p>
                                </div>
                                <div class="col-md-12 form-group">
                                    <label for="description">Omschrijving (optioneel)</label>
                                    <textarea type="text" class="form-control" v-model="form.dimensions[index].desc"
                                        rows="3"></textarea>
                                </div>
                            </div>
                        </article>
                    </div>
                </section>
                <section v-if="step ==3">
                    <div class="card" id="vehicle-package">
                        <article class="card-body" v-if="vehicleInfo">
                            <header class="mb-4">
                                <h4 class="card-title text-center">Kies een dienst</h4>
                            </header>
                            <div class="row no-gutters row_corieor" v-for="(cm,index) in form.courier_methods"
                                :key="index">
                                <div class="col-md-5">
                                    <div class="info-main">
                                        <p><strong>{{ cm.name }}</strong></p>
                                        <p> {{ cm.desc }}</p>
                                    </div>
                                    <div class="d-flex" style="width: 200%;">
                                        <input type="checkbox" :value="cm.discount" @change="selectDiscount(index, $event)" style="margin-top:5px; margin-right:10px !important;"/>
                                        <label><strong> Dit is mijn eerste bestelling bij Easy2send (30%) introductiekorting </strong></label>
                                    </div>
                                </div>
                                <aside class="col-md-4">
                                    <div class="form-check">
                                        <input class="form-check-input" type="radio" @keypress.enter.prevent
                                            value="doordeweeks" v-model="form.service[index]"
                                            @change="selectDays(index, $event, 0)"
                                            :id="'flexCheckDefault'+index+cm.name">
                                        <label class="form-check-label" :for="'flexCheckDefault'+index+cm.name">
                                            doordeweeks
                                        </label>
                                    </div>
                                    <div class="form-check">
                                        <input class="form-check-input" type="radio" @keypress.enter.prevent
                                            value="zaterdag leveren" v-model="form.service[index]"
                                            @change="selectDays(index, $event, 25)"
                                            :id="'flexCheckDefault1'+index+cm.name">
                                        <label class="form-check-label" :for="'flexCheckDefault1'+index+cm.name">
                                            zaterdag leveren
                                        </label>
                                    </div>
                                    <div class="form-check">
                                        <input class="form-check-input" type="radio" @keypress.enter.prevent
                                            value="zondag" v-model="form.service[index]"
                                            @change="selectDays(index, $event, 50)"
                                            :id="'flexCheckDefault2'+index+cm.name">
                                        <label class="form-check-label" :for="'flexCheckDefault2'+index+cm.name">
                                            zondag leveren
                                        </label>
                                    </div>
                                </aside>
                                <aside class="col-sm-3">
                                    <div class="info-aside text-center">
                                        <div class="price-wrap product1 text-center">
                                            <span class="price h2">€ {{ form.courier_methods[index].price }}</span>
                                        </div>
                                        <br/>
                                        <p>
                                            <a @click="selectVehicle(index)" class="btn btn-primary btn-block"> <i
                                                    class="fas fa-truck"> </i> KIES OPTIE </a>
                                        </p>
                                    </div>
                                </aside>
                            </div>
                        </article>
                    </div>
                </section>
                <section v-if="step==4">
                    <div class="card" id="vehicle-package">
                        <article class="card-body">
                            <header class="mb-4">
                                <h4 class="card-title text-center">Ophaal/Aflever Datum</h4>
                            </header>
                            <div class="row form-row">
                                <div class="col-md-6 form-group">
                                    <label for="date">Ophaaldatum<span class="req">*</span></label>
                                    <input type="date" class="form-control" id="date"
                                        :min="new Date().toISOString().substr(0, 10)" v-model="form.date"
                                        @keypress.enter.prevent @click="validate($event)" :class="!this.validationErrors.date ? 'valid' : 'is-invalid'">
                                </div>
                                <div class="col-md-6 form-group">
                                    <label for="time">Ophaaltijd<span class="req">*</span></label>
                                    <input type="time" class="form-control" id="time" v-model="form.time"
                                        @keypress.enter.prevent @click="validate($event)" :class="!this.validationErrors.date ? 'valid' : 'is-invalid'">
                                </div>
                            </div>
                        </article>
                        <article class="card-body">
                            <header class="mb-4">
                                <h4 class="card-title">Ophaaladres</h4>
                            </header>
                            <div class="form-row">
                                <div class="col form-group">
                                    <label for="CompanyName">Bedrijfsnaam<span class="req">*</span></label>
                                    <input type="text" class="form-control companyName"
                                        v-model="form.pickupaddress.CompanyName" name="Pickup[CompanyName]"
                                        id="PickupCompanyName" value="" @keypress.enter.prevent @keyup="validate($event)" :class="!this.validationErrors.p_CompanyName ? 'valid' : 'is-invalid'" >
                                </div>
                                <!-- form-group end.// -->
                                <div class="col form-group">
                                    <label for="Reference">Referentie</label>
                                    <input type="text" class="form-control" v-model="form.pickupaddress.Reference"
                                        name="Pickup[Reference]" id="PickupReference" value="" @keypress.enter.prevent >
                                </div>
                                <!-- form-group end.// -->
                            </div>
                            <!-- form-row end.// -->
                            <div class="form-row">
                                <!-- form-group end.// -->
                                <div class="col form-group">
                                    <label for="FirstName">Voornaam<span class="req">*</span></label>
                                    <input type="text" class="form-control" v-model="form.pickupaddress.FirstName"
                                        id="PickupFirstName" required="" @keypress.enter.prevent @keyup="validate($event)" :class="!this.validationErrors.p_FirstName ? 'valid' : 'is-invalid'" >
                                    <span class="invalid-feedback" v-if="errors" >{{errors}}</span>
                                    <!-- <div class="valid-feedback">Correct!</div> -->
                                    <!-- <div class="invalid-feedback">Vul een geldige voornaam in.</div> -->
                                </div>
                                <!-- form-group end.// -->
                                <div class="col form-group">
                                    <label for="LastName">Achternaam<span class="req">*</span></label>
                                    <input type="text" class="form-control" v-model="form.pickupaddress.LastName"
                                        name="Pickup[LastName]" id="PickupLastName" value="" @keypress.enter.prevent @keyup="validate($event)" :class="!this.validationErrors.p_LastName ? 'valid' : 'is-invalid'"
                                        required="">
                                    
                                </div>
                                <!-- form-group end.// -->
                            </div>
                            <!-- form-row end.// -->
                            <div class="form-row">
                                <div class="col form-group">
                                    <label for="PhoneNumber">Telefoonnummer<span class="req">*</span></label>
                                    <input type="text" class="form-control" v-model="form.pickupaddress.PhoneNumber"
                                        name="Pickup[PhoneNumber]" id="PickupPhoneNumber" value=""
                                        @keypress.enter.prevent @keyup="validate($event)" :class="!this.validationErrors.p_PhoneNumber ? 'valid' : 'is-invalid'" required="">
                                    
                                </div>
                                <!-- form-group end.// -->
                                <div class="col form-group">
                                    <label for="Email">E-mail<span class="req">*</span></label>
                                    <input type="email" class="form-control" v-model="form.pickupaddress.email"
                                        name="Pickup[Email]" id="PickupEmail" value="" @keypress.enter.prevent @keyup="validate($event)" :class="!this.validationErrors.p_email ? 'valid' : 'is-invalid'"
                                        required="">
                                    
                                </div>
                                <!-- form-group end.// -->
                            </div>
                            <!-- form-row end.// -->
                            <div class="form-row">
                                <div class="col form-group">
                                    <label for="Address">Straatnaam + Huisnummer<span class="req">*</span></label>
                                    <!--div v-if="form.pickupaddress.street_number === ''">                                    
                                     <input type="text" class="form-control" name="Pickup[Address]" readonly
                                        id="PickupAddress" value=""
                                        @keypress.enter.prevent @keyup="validate($event)" :class="!this.validationErrors.p_Address ? 'valid' : 'is-invalid'">
                                    </div>
                                    <div v-else-->
                                     <input type="text" class="form-control" name="Pickup[Address]" readonly
                                        v-model="form.pickupaddress.Address" id="PickupAddress" value=""
                                        @keypress.enter.prevent @keyup="validate($event)" :class="!this.validationErrors.p_Address ? 'valid' : 'is-invalid'">
                                    <!--/div-->                                       
                                </div>
                                <!-- form-group end.// -->
                                <div class="col form-group">
                                    <label for="ZipCode">Postcode<span class="req">*</span></label>
                                    <input type="text" class="form-control" readonly name="Pickup[ZipCode]"
                                        v-model="form.pickupaddress.ZipCode" id="PickupZipCode" value=""
                                        @keypress.enter.prevent @keyup="validate($event)" :class="!this.validationErrors.p_ZipCode ? 'valid' : 'is-invalid'">
                                    
                                </div>
                                <!-- form-group end.// -->
                            </div>
                            <!-- form-row end.// -->
                            <div class="form-row">
                                <div class="form-group col-md-6">
                                    <label for="City">Woonplaats</label>
                                    <input type="text" class="form-control" readonly name="Pickup[City]" id="PickupCity"
                                        v-model="form.pickupaddress.City" value="" @keypress.enter.prevent @keyup="validate($event)" :class="!this.validationErrors.p_City ? 'valid' : 'is-invalid'">
                                    <div class="invalid-feedback">Vul een geldige woonplaats in.</div>
                                </div>
                                <!-- form-group end.// -->
                                <div class="form-group col-md-6">
                                    <label for="Country">Land<span class="req">*</span></label>
                                    <input type="text" class="form-control" readonly name="Pickup[Country]"
                                        id="PickupCountry" v-model="form.pickupaddress.Country" value=""
                                        @keypress.enter.prevent @keyup="validate($event)" :class="!this.validationErrors.p_Country ? 'valid' : 'is-invalid'">
                                </div>
                                <!-- form-group end.// -->
                            </div>
                            <!-- form-row.// -->
                            <b-form-checkbox
                            id="is_billing_address_same"
                            v-model="form.is_billing_address_same"
                            name="is_billing_address_same"
                            value="yes"
                            unchecked-value="no"
                            @change="updateFactuurAddress"
                            >
                            Factuuradres wijkt af van ophaaladres
                            </b-form-checkbox>
                        </article>
                        <article v-if="this.form.is_billing_address_same == 'yes'" class="card-body">
                            <header class="mb-4">
                                <h4 class="card-title">Factuuradres </h4>
                            </header>
                            <div class="form-row">
                                <div class="col form-group">
                                    <label for="CompanyName">Bedrijfsnaam<span class="req">*</span></label>
                                    <input type="text" class="form-control companyName"
                                        v-model="form.factuur.CompanyName" name="factuur[CompanyName]"
                                        id="factuurCompanyName" value="" @keypress.enter.prevent @keyup="validate($event)" :class="!this.validationErrors.f_CompanyName ? 'valid' : 'is-invalid'" >
                                </div>
                                <!-- form-group end.// -->
                                <div class="col form-group">
                                    <label for="Reference">Referentie</label>
                                    <input type="text" class="form-control" name="factuur[Reference]"
                                        v-model="form.factuur.Reference" id="factuurReference" value=""
                                        @keypress.enter.prevent >
                                </div>
                                <!-- form-group end.// -->
                            </div>
                            <!-- form-row end.// -->
                            <div class="form-row">
                                <!-- form-group end.// -->
                                <div class="col form-group">
                                    <label for="FirstName">Voornaam<span class="req">*</span></label>
                                    <input type="text" class="form-control" name="factuur[FirstName]"
                                        v-model="form.factuur.FirstName" id="factuurFirstName" value=""
                                        @keypress.enter.prevent @keyup="validate($event)" :class="!this.validationErrors.f_FirstName ? 'valid' : 'is-invalid'" required="">
                                    <!-- <div class="valid-feedback">Correct!</div> -->
                                    
                                </div>
                                <!-- form-group end.// -->
                                <div class="col form-group">
                                    <label for="LastName">Achternaam<span class="req">*</span></label>
                                    <input type="text" class="form-control" name="factuur[LastName]"
                                        v-model="form.factuur.LastName" id="factuurLastName" value=""
                                        @keypress.enter.prevent @keyup="validate($event)" :class="!this.validationErrors.f_LastName ? 'valid' : 'is-invalid'" required="">
                                    
                                </div>
                                <!-- form-group end.// -->
                            </div>
                            <!-- form-row end.// -->
                            <div class="form-row">
                                <div class="col form-group">
                                    <label for="PhoneNumber">Telefoonnummer<span class="req">*</span></label>
                                    <input type="text" class="form-control" name="factuur[PhoneNumber]"
                                        v-model="form.factuur.PhoneNumber" id="factuurPhoneNumber" value=""
                                        @keypress.enter.prevent @keyup="validate($event)" :class="!this.validationErrors.f_PhoneNumber ? 'valid' : 'is-invalid'" required="">
                                    
                                </div>
                                <!-- form-group end.// -->
                                <div class="col form-group">
                                    <label for="Email">E-mail<span class="req">*</span></label>
                                    <input type="email" class="form-control" name="factuur[Email]"
                                        v-model="form.factuur.email" id="factuurEmail" value=""
                                        @keypress.enter.prevent @keyup="validate($event)" :class="!this.validationErrors.f_Email ? 'valid' : 'is-invalid'" required="">
                                    
                                </div>
                                <!-- form-group end.// -->
                            </div>
                            <!-- form-row end.// -->
                            <div class="form-row">
                                <div class="col form-group">
                                    <label for="Address">Straatnaam + Huisnummer<span class="req">*</span></label>
                                    <input type="text" class="form-control" name="factuur[Address]"
                                        v-model="form.factuur.Address" id="factuurAddress" value=""
                                        @keypress.enter.prevent @keyup="validate($event)" :class="!this.validationErrors.f_Address ? 'valid' : 'is-invalid'">
                                </div>
                                <!-- form-group end.// -->
                                <div class="col form-group">
                                    <label for="ZipCode">Postcode<span class="req">*</span></label>
                                    <input type="text" class="form-control" name="factuur[ZipCode]"
                                        v-model="form.factuur.ZipCode" id="factuurZipCode" value=""
                                        @keypress.enter.prevent @keyup="validate($event)" :class="!this.validationErrors.f_ZipCode ? 'valid' : 'is-invalid'">
                                    
                                </div>
                                <!-- form-group end.// -->
                            </div>
                            <!-- form-row end.// -->
                            <div class="form-row">
                                <div class="form-group col-md-6">
                                    <label for="City">Woonplaats<span class="req">*</span></label>
                                    <input type="text" class="form-control" name="factuur[City]"
                                        v-model="form.factuur.City" id="factuurCity" value=""
                                        @keypress.enter.prevent @keyup="validate($event)" :class="!this.validationErrors.f_City ? 'valid' : 'is-invalid'">
                                    
                                </div>
                                <!-- form-group end.// -->
                                <div class="form-group col-md-6">
                                    <label for="Country">Land<span class="req">*</span></label>
                                    <input type="text" class="form-control" name="factuur[Country]"
                                        id="factuurCountry" v-model="form.factuur.Country" value=""
                                        @keypress.enter.prevent @keyup="validate($event)" :class="!this.validationErrors.f_Country ? 'valid' : 'is-invalid'">
                                </div>
                                <!-- form-group end.// -->
                            </div>
                            <!-- form-row.// -->
                            <hr>
                        </article>
                        <article class="card-body">
                            <header class="mb-4">
                                <h4 class="card-title">Afleveradres</h4>
                            </header>
                            <div class="form-row">
                                <div class="col form-group">
                                    <label for="CompanyName">Bedrijfsnaam<span class="req">*</span></label>
                                    <input type="text" class="form-control companyName"
                                        v-model="form.deliveryaddress.CompanyName" name="Delivery[CompanyName]"
                                        id="DeliveryCompanyName" value="" @keypress.enter.prevent @keyup="validate($event)" :class="!this.validationErrors.d_CompanyName ? 'valid' : 'is-invalid'" >
                                </div>
                                <!-- form-group end.// -->
                                <div class="col form-group">
                                    <label for="Reference">Referentie</label>
                                    <input type="text" class="form-control" name="Delivery[Reference]"
                                        v-model="form.deliveryaddress.Reference" id="DeliveryReference" value=""
                                        @keypress.enter.prevent >
                                </div>
                                <!-- form-group end.// -->
                            </div>
                            <!-- form-row end.// -->
                            <div class="form-row">
                                <!-- form-group end.// -->
                                <div class="col form-group">
                                    <label for="FirstName">Voornaam<span class="req">*</span></label>
                                    <input type="text" class="form-control" name="Delivery[FirstName]"
                                        v-model="form.deliveryaddress.FirstName" id="DeliveryFirstName" value=""
                                        @keypress.enter.prevent @keyup="validate($event)" :class="!this.validationErrors.d_FirstName ? 'valid' : 'is-invalid'" required="">
                                    <!-- <div class="valid-feedback">Correct!</div> -->
                                    
                                </div>
                                <!-- form-group end.// -->
                                <div class="col form-group">
                                    <label for="LastName">Achternaam<span class="req">*</span></label>
                                    <input type="text" class="form-control" name="Delivery[LastName]"
                                        v-model="form.deliveryaddress.LastName" id="DeliveryLastName" value=""
                                        @keypress.enter.prevent @keyup="validate($event)" :class="!this.validationErrors.d_LastName ? 'valid' : 'is-invalid'" required="">
                                    
                                </div>
                                <!-- form-group end.// -->
                            </div>
                            <!-- form-row end.// -->
                            <div class="form-row">
                                <div class="col form-group">
                                    <label for="PhoneNumber">Telefoonnummer<span class="req">*</span></label>
                                    <input type="text" class="form-control" name="Delivery[PhoneNumber]"
                                        v-model="form.deliveryaddress.PhoneNumber" id="DeliveryPhoneNumber" value=""
                                        @keypress.enter.prevent @keyup="validate($event)" :class="!this.validationErrors.d_PhoneNumber ? 'valid' : 'is-invalid'" required="">
                                    
                                </div>
                                <!-- form-group end.// -->
                                <div class="col form-group">
                                    <label for="Email">E-mail<span class="req">*</span></label>
                                    <input type="email" class="form-control" name="Delivery[Email]"
                                        v-model="form.deliveryaddress.Email" id="DeliveryEmail" value=""
                                        @keypress.enter.prevent @keyup="validate($event)" :class="!this.validationErrors.d_Email ? 'valid' : 'is-invalid'" required="">
                                    
                                </div>
                                <!-- form-group end.// -->
                            </div>
                            <!-- form-row end.// -->
                            <div class="form-row">
                                <div class="col form-group">
                                    <label for="Address">Straatnaam + Huisnummer<span class="req">*</span></label>
                                    <!--div v-if="form.deliveryaddress.street_number === ''">                                    
                                    <input type="text" class="form-control" readonly name="Delivery[Address]" id="DeliveryAddress" value=""
                                        @keypress.enter.prevent @keyup="validate($event)" :class="!this.validationErrors.d_Address ? 'valid' : 'is-invalid'">
                                    </div>
                                    <div v-else-->
                                    <input type="text" class="form-control" readonly name="Delivery[Address]"
                                        v-model="form.deliveryaddress.Address" id="DeliveryAddress" value=""
                                        @keypress.enter.prevent @keyup="validate($event)" :class="!this.validationErrors.d_Address ? 'valid' : 'is-invalid'">
                                    <!--/div-->
                                </div>
                                <!-- form-group end.// -->
                                <div class="col form-group">
                                    <label for="ZipCode">Postcode<span class="req">*</span></label>
                                    <input type="text" class="form-control" readonly name="Delivery[ZipCode]"
                                        v-model="form.deliveryaddress.ZipCode" id="DeliveryZipCode" value=""
                                        @keypress.enter.prevent @keyup="validate($event)" :class="!this.validationErrors.d_ZipCode ? 'valid' : 'is-invalid'">
                                    
                                </div>
                                <!-- form-group end.// -->
                            </div>
                            <!-- form-row end.// -->
                            <div class="form-row">
                                <div class="form-group col-md-6">
                                    <label for="City">Woonplaats<span class="req">*</span></label>
                                    <input type="text" class="form-control" readonly name="Delivery[City]"
                                        v-model="form.deliveryaddress.City" id="DeliveryCity" value=""
                                        @keypress.enter.prevent @keyup="validate($event)" :class="!this.validationErrors.d_City ? 'valid' : 'is-invalid'">
                                    
                                </div>
                                <!-- form-group end.// -->
                                <div class="form-group col-md-6">
                                    <label for="Country">Land<span class="req">*</span></label>
                                    <input type="text" class="form-control" readonly name="Delivery[Country]"
                                        id="DeliveryCountry" v-model="form.deliveryaddress.Country" value=""
                                        @keypress.enter.prevent @keyup="validate($event)" :class="!this.validationErrors.d_Country ? 'valid' : 'is-invalid'">
                                </div>
                                <!-- form-group end.// -->
                            </div>
                            <!-- form-row.// -->
                            <hr>
                        </article>
                    </div>
                </section>
                <section v-if="step==5">
                    <div class="card bordercolor" v-if="this.form">
                        <div class="card-body">
                            <h3 class="mb-3">Samenvatting</h3>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <table class="table table-borderless">
                                    <tbody>
                                        <tr>
                                            <th>Voornaam</th>
                                            <td>{{ this.form.deliveryaddress.FirstName }}</td>
                                        </tr>
                                        <tr>
                                            <th>Achternaam</th>
                                            <td>{{ this.form.deliveryaddress.LastName }}</td>
                                        </tr>
                                        <tr>
                                            <th>Tel</th>
                                            <td>{{ this.form.deliveryaddress.PhoneNumber }}</td>
                                        </tr>
                                        <tr>
                                            <th>Voertuig</th>
                                            <td>{{this.vehicleInfo.name}} </td>
                                        </tr>
                                        <tr>
                                            <th>Van Adres</th>
                                            <td>{{ this.form.location_from }}</td>
                                        </tr>
                                        <tr>
                                            <th>Naar Adres</th>
                                            <td>{{ this.form.location_to }}</td>
                                        </tr>
                                        <tr>
                                            <th>Aant km</th>
                                            <td>{{ this.form.distance }}</td>
                                        </tr>
                                        <tr>
                                            <th>Dienst Tijd</th>
                                            <td>{{ this.form.selectd_day }}</td>
                                        </tr>
                                        <tr>
                                            <th>Prijs</th>
                                            <td>EUR {{ this.form.price }}<br/>
                                                EUR {{ this.form.pricewithtax }} ind. BTW
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>Ophaal datum/tijd</th>
                                            <td>{{ this.formatDate(this.form.date) }} {{ this.form.time }}</td>
                                        </tr>
                                        <tr>
                                            <th>Totaalpakketten</th>
                                            <td class="text-gray-800">
                                                <div class="table-responsive">
                                                    <table
                                                        class="table align-middle table-row-dashed fs-6 gy-5 no-footer tborder">
                                                        <thead>
                                                            <tr>
                                                                <th>Omschrijving</th>
                                                                <th>Lengte (cm)</th>
                                                                <th>Breedte (cm)</th>
                                                                <th>Hoogte (cm)</th>
                                                                <th>Gewicht (kg)</th>
                                                                <th>Aantal</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody class="package-item">
                                                            <tr v-for="packageItem in this.form.dimensions"
                                                                :key="packageItem.index">
                                                                <td>{{packageItem.desc}}</td>
                                                                <td>{{packageItem.length}}</td>
                                                                <td>{{packageItem.width}}</td>
                                                                <td>{{packageItem.height}}</td>
                                                                <td>{{packageItem.weight}}</td>
                                                                <td>{{packageItem.number}}</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div class="container">
                                    <div class="row">
                                        <div class="col bcolor">
                                            <div class="theading" style="text-align: center;">
                                                Ophaaladres
                                            </div>
                                            <div class="table-responsive">
                                                <table class="table align-middle table-row-dashed fs-6 gy-5 no-footer">
                                                    <tbody class="package-item">
                                                        <tr>
                                                            <td class="text-muted">Voornaam</td>
                                                            <td>{{this.form.pickupaddress.FirstName}}</td>
                                                        </tr>
                                                        <tr>
                                                            <td class="text-muted">Achternaam</td>
                                                            <td>{{this.form.pickupaddress.LastName}}</td>
                                                        </tr>
                                                        <tr>
                                                            <td class="text-muted">Telefoonnummer</td>
                                                            <td>{{this.form.pickupaddress.PhoneNumber}}</td>
                                                        </tr>
                                                        <tr>
                                                            <td class="text-muted">E-mail</td>
                                                            <td>{{this.form.pickupaddress.email}}</td>
                                                        </tr>
                                                        <tr>
                                                            <td class="text-muted">Postcode</td>
                                                            <td>{{this.form.pickupaddress.ZipCode}}</td>
                                                        </tr>
                                                        <tr>
                                                            <td class="text-muted">Adres</td>
                                                            <td>{{this.form.pickupaddress.Address}}</td>
                                                        </tr>
                                                        <tr>
                                                            <td class="text-muted">Stad</td>
                                                            <td>{{this.form.pickupaddress.City}}</td>
                                                        </tr>
                                                        <tr>
                                                            <td class="text-muted">Bedrijfsnaam</td>
                                                            <td>{{this.form.pickupaddress.CompanyName}}</td>
                                                        </tr>
                                                        <tr>
                                                            <td class="text-muted">Referentie</td>
                                                            <td>{{this.form.pickupaddress.Reference}}</td>
                                                        </tr>
                                                        <tr>
                                                            <td class="text-muted">Land</td>
                                                            <td>{{this.form.pickupaddress.Country}}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        <div class="col bcolor">
                                            <div class="theading" style="text-align: center;">
                                                Bezorgadres
                                            </div>
                                            <div class="table-responsive">
                                                <table class="table align-middle table-row-dashed fs-6 gy-5 no-footer">
                                                    <tbody class="package-item">
                                                        <tr>
                                                            <td class="text-muted">Voornaam</td>
                                                            <td>{{this.form.deliveryaddress.FirstName}}</td>
                                                        </tr>
                                                        <tr>
                                                            <td class="text-muted">Achternaam</td>
                                                            <td>{{this.form.deliveryaddress.LastName}}</td>
                                                        </tr>
                                                        <tr>
                                                            <td class="text-muted">Telefoonnummer</td>
                                                            <td>{{this.form.deliveryaddress.PhoneNumber}}</td>
                                                        </tr>
                                                        <tr>
                                                            <td class="text-muted">E-mail</td>
                                                            <td>{{this.form.deliveryaddress.Email}}</td>
                                                        </tr>
                                                        <tr>
                                                            <td class="text-muted">Postcode</td>
                                                            <td>{{this.form.deliveryaddress.ZipCode}}</td>
                                                        </tr>
                                                        <tr>
                                                            <td class="text-muted">Adres</td>
                                                            <td>{{this.form.deliveryaddress.Address}}</td>
                                                        </tr>
                                                        <tr>
                                                            <td class="text-muted">Stad</td>
                                                            <td>{{this.form.deliveryaddress.City}}</td>
                                                        </tr>
                                                        <tr>
                                                            <td class="text-muted">Bedrijfsnaam</td>
                                                            <td>{{this.form.deliveryaddress.CompanyName}}</td>
                                                        </tr>
                                                        <tr>
                                                            <td class="text-muted">Referentie</td>
                                                            <td>{{this.form.deliveryaddress.Reference}}</td>
                                                        </tr>
                                                        <tr>
                                                            <td class="text-muted">Land</td>
                                                            <td>{{this.form.deliveryaddress.Country}}
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        <div class="col bcolor"  v-if="this.form.is_billing_address_same == 'yes'">
                                            <div class="theading" style="text-align: center;">
                                                Factuuradres
                                            </div>
                                            <div class="table-responsive">
                                                <table class="table align-middle table-row-dashed fs-6 gy-5 no-footer">
                                                    <tbody class="package-item">
                                                        <tr>
                                                            <td class="text-muted">Voornaam</td>
                                                            <td>{{this.form.factuur.FirstName}}</td>
                                                        </tr>
                                                        <tr>
                                                            <td class="text-muted">Achternaam</td>
                                                            <td>{{this.form.factuur.LastName}}</td>
                                                        </tr>
                                                        <tr>
                                                            <td class="text-muted">Telefoonnummer</td>
                                                            <td>{{this.form.factuur.PhoneNumber}}</td>
                                                        </tr>
                                                        <tr>
                                                            <td class="text-muted">E-mail</td>
                                                            <td>{{this.form.factuur.email}}</td>
                                                        </tr>
                                                        <tr>
                                                            <td class="text-muted">Postcode</td>
                                                            <td>{{this.form.factuur.ZipCode}}</td>
                                                        </tr>
                                                        <tr>
                                                            <td class="text-muted">Adres</td>
                                                            <td>{{this.form.factuur.Address}}</td>
                                                        </tr>
                                                        <tr>
                                                            <td class="text-muted">Stad</td>
                                                            <td>{{this.form.factuur.City}}</td>
                                                        </tr>
                                                        <tr>
                                                            <td class="text-muted">Bedrijfsnaam</td>
                                                            <td>{{this.form.factuur.CompanyName}}</td>
                                                        </tr>
                                                        <tr>
                                                            <td class="text-muted">Referentie</td>
                                                            <td>{{this.form.factuur.Reference}}</td>
                                                        </tr>
                                                        <tr>
                                                            <td class="text-muted">Land</td>
                                                            <td>{{this.form.factuur.Country}}
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col bcolor">
                                            <div class="theading" style="text-align: center;">
                                                Leveropties
                                            </div>
                                            <div class="table-responsive">
                                                <table class="table align-middle table-row-dashed fs-6 gy-5 no-footer">
                                                    <tbody class="package-item">
                                                        <tr>
                                                            <td class="text-muted">Gekozen optie</td>
                                                            <td>{{ form.courier_methods[form.selectd_index].name }}</td>
                                                        </tr>
                                                        <tr>
                                                            <td class="text-muted">Omschrijving</td>
                                                            <td>{{ form.courier_methods[form.selectd_index].desc }}</td>
                                                        </tr>
                                                        <tr>
                                                          <td colspan="2">
                                                          <b-form-checkbox
                                                              id="terms"
                                                              v-model="form.accepted"
                                                              name="terms"
                                                              value="yes"
                                                              unchecked-value="no"
                                                              @change="validateTerms"
                                                            >
                                                              Ik ga akkoord met de <a href="javascript:void(0)" v-b-modal.modalTerms>specifieke voorwaarden</a> van de configurator
                                                            </b-form-checkbox>
                                                            <div class="invalid">{{this.validationErrors.accepted}}</div> 
                                                            <b-modal id="modalTerms" title="Specifieke voorwaarden" hide-footer>
                                                                <div v-html="termspopupcontent"></div>
                                                            </b-modal>
                                                          </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section v-if="step ==6">
                    <div class="card">
                        <div id="loading" v-if="!results">
                            <img id="loading-image" src="https://c.tenor.com/I6kN-6X7nhAAAAAj/loading-buffering.gif"
                                alt="Loading..." />
                        </div>
                        <article class="card-body" v-if="success && results">
                            <div class="alert alert-success" v-if="success" role="alert" style="margin: 0px;">
                                <div>{{success}}</div>
                            </div>
                        </article>
                    </div>
                </section>
                <br/>
                <div class="row">
                    <div class="col-md-12 text-center">
                        <button v-if="step !==1 && step !==6 " @click.prevent="prevStep" class="btn btn-primary"
                            id="vorige">Vorige</button>

                        <button v-if="step !==5 && step !=3 && step !==6" @click.prevent="nextStep"
                            class="btn btn-primary" id="volgende">Volgende</button>

                        <button v-if="step == 5" @click.prevent="nextStep" class="btn btn-primary">Bevestigen</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>

<script>

    export default {
        name: 'home',
        data() {
            return {
                errorForm:{
                    pickup:{
                        address: null,
                        addressTemp: null
                    },
                    delivery:{
                        address: null,
                        addressTemp: null
                    },
                },
                array:[],
                errors: null,
                termspopupcontent:null,
				validationErrors:{},
                dimensionErrors:{},
                popupMessage: null,
                results: null,
                items: null,
                success: null,
                packages: [1, 2, 3, 4, 5],
                step: 1,
                itration: 0,
                vehicleInfo: null,
                loading: false,
                geometryData: {
                    geometry1: null,
                    geometry2: null
                },
                form: {
                    is_billing_address_same:'no',
                    selectd_courier_method: null,
                    selectd_day: null,
                    selected_vehicle: null,
                    location_from: null,
                    selectd_index: null,
                    location_to: null,
                    selectedPackage: 1,
                    date: null,
                    time: null,
                    price: null,
                    pricewithtax: null,
                    distance: 0,
                    accepted:'no',
                    pickupaddress: {
                        FirstName: null,
                        LastName: null,
                        PhoneNumber: null,
                        CompanyName: null,
                        email: null,
                        Reference: null,
                        Address: null,
                        ZipCode: null,
                        City: null,
                        Country: null,
                        street_number: null,
                    },
                    factuur: {
                        FirstName: null,
                        LastName: null,
                        PhoneNumber: null,
                        CompanyName: null,
                        email: null,
                        Reference: null,
                        Address: null,
                        ZipCode: null,
                        City: null,
                        Country: null,
                    },
                    deliveryaddress: {
                        FirstName: null,
                        LastName: null,
                        PhoneNumber: null,
                        CompanyName: null,
                        Email: null,
                        Reference: null,
                        Address: null,
                        ZipCode: null,
                        City: null,
                        Country: null,
                        street_number: null,
                    },
                    dimensions: [{
                        length: null,
                        width: null,
                        height: null,
                        weight: null,
                        number: 1,
                        desc: null
                    }],
                    service: {
                        spoed_koerier: null,
                        change_zo: null,
                        volgende_koerier: null,
                    },
                    courier_methods: {
                        spoed_koerier: {
                            name: 'Spoed Koerier',
                            desc: 'Binnen 30 tot 40 minuten geladen en direct door naar het afleveradres.',
                            percentage: 0,
                            price: 0,
                            discount: 1,
                            calcprice: 0
                        },
                        change_zo: {
                            name: 'Same Day',
                            desc: 'Voor zendingen met iets minder spoed. In ieder geval dezelfde dag bezorgd. Jouw order moet voor 13:00 bij ons binnen zijn.',
                            percentage: 20,
                            price: 0,
                            discount: 1,
                            calcprice: 0
                        },
                        volgende_koerier: {
                            name: 'Next Day',
                            desc: 'Vandaag laden en morgen leveren.',
                            percentage: 25,
                            price: 0,
                            discount: 1,
                            calcprice: 0
                        }
                    }
                },
                totalSteps: 5,
                title: 'VUL IN EN KRIJG DIRECT EEN PRIJS VOOR JE TRANSPORT!',
				submitted: false
            }
        },
        methods: {
            formatDate(date){
                var date = new Date(date);

                var result = (date.getDate() > 9 ? '' : '0') + date.getDate() +'/'+ ((date.getMonth() + 1) > 9 ? '' : '0') + (date.getMonth() + 1) + '/'+ date.getFullYear();

                return result;
            },
            resetModal() {

            },
            handleOk(bvModalEvt) {
                // Prevent modal from closing
                bvModalEvt.preventDefault()
                // Trigger submit handler
                this.handleSubmit()
            },
            handleSubmit() {
                
                this.validationErrors = {};
                if (!this.errorForm.pickup.address && !this.errorForm.pickup.addressTemp) {
                    this.validationErrors['pickupstreetaddress'] = true;
                }

                if (!this.errorForm.delivery.address && !this.errorForm.delivery.addressTemp) {
                    this.validationErrors['deliverystreetaddress'] = true;
                }
                
                if(this.isValid() > 0) return false;
                // Push the name to submitted names
                //this.submittedNames.push(this.errorForm.name)
                // Hide the modal manually
                this.$nextTick(() => {
                this.$bvModal.hide('errorModal')
                this.step++;
                })
            },
            reloadPage() {
                window.location.reload()
            },
			validate(e){ 
                if(!e.target.value){
                    e.target.classList.add('is-invalid');
                    return true;
                }
                e.target.classList.remove('is-invalid');
                return false;
            },
            updateAddress(e){
                if(e.target.value && e.target.name == 'pickup_street_address'){
                    this.form.pickupaddress.Address = e.target.value
                }

                if(e.target.value && e.target.name == 'delivery_street_address'){
                    this.form.deliveryaddress.Address = e.target.value
                }
            },
            isValid(){
                var errors = JSON.parse(JSON.stringify(this.validationErrors));
                return Object.keys(errors).length;
            },
            checkIfValidDymensions(){
                var errors = JSON.parse(JSON.stringify(this.dimensionErrors));
                return Object.keys(errors).length;
            },
            checkProperties(obj) {
                for (var key in obj) {
                    console.log(obj[key]);
                    if (obj[key])
                        return false;
                }
                return true;
            },
            updateFactuurAddress(){
                let array =[
                        "FirstName",
                        "LastName",
                        "PhoneNumber",
                        "CompanyName",
                        "email"
                    ]
                if(this.form.is_billing_address_same == 'yes'){
                    for (var index in this.form.pickupaddress) { 
                        let _is_ = array.includes(index);
                        if(_is_){
                            this.form.factuur[index] = this.form.pickupaddress[index]
                        }
                    }
                }else{
                    for (var index in this.form.factuur) { 
                        this.form.factuur[index] = null;
                    }
                }
            },
            validateTerms(){
                this.validationErrors = {};
                if (this.form.accepted == 'no') {
                    this.validationErrors['accepted'] = 'Accepteer alstublieft de voorwaarden';
                }
            },
            nextStep: function () {
                this.validationErrors = {};
                if (this.step == 1) {
                    if (!this.form.location_from) {
                        this.validationErrors['location_from'] = 'Voer uw formulierlocatie in';
                        return;
                    }

                    if (!this.form.location_to) {
                        this.validationErrors['location_to'] = 'Vul a.u.b. uw bezorglocatie in';
                        return;
                    }
                    if(this.isValid() > 0) return false;

                    if (this.form.pickupaddress.street_number !== "") {            
                        if (!this.form.location_from.includes(this.form.pickupaddress.street_number)) {       
                            this.form.pickupaddress.street_number = "";
                        }                                
                    }      
                    
                    if (this.form.deliveryaddress.street_number !== "") {            
                        if (!this.form.location_to.includes(this.form.deliveryaddress.street_number)) {       
                            this.form.deliveryaddress.street_number = "";
                        }                                
                    }  
                }


                if (this.step == 2) {
                    this.array = [];
                    for (var index in this.form.dimensions) { 
                        this.dimensionErrors[index] = {};
                        if(!this.form.dimensions[index]['width']){
                        this.dimensionErrors[index]['width'] = 'width '+index+' is required';
                        }
                        if(!this.form.dimensions[index]['length']){
                        this.dimensionErrors[index]['length'] = 'length '+index+' is required';
                        }
                        if(!this.form.dimensions[index]['height']){
                        this.dimensionErrors[index]['height'] = 'height '+index+' is required';
                        }
                        if(!this.form.dimensions[index]['weight']){
                        this.dimensionErrors[index]['weight'] = 'weight '+index+' is required';
                        }
                        if(!this.form.dimensions[index]['number']){
                        this.dimensionErrors[index]['number'] = 'number '+index+' is required';
                        }

                        if(Object.keys(this.dimensionErrors[index]).length !== 0){
                        this.array.push(index);
                        }
                    }
                    if(this.array.length > 0){
                        return false;
                    }

                    var promise = axios
                        .post('api/v1/get_vehicles', this.form)
                        .then(response => {
                            console.log(response);
                            this.vehicleInfo = response.data.data
                            for (var index in this.form.courier_methods) {
                                var calculated_price = this.vehicleInfo.calculated_price;
                                if (this.form.courier_methods[index].percentage > 0) {
                                    this.form.courier_methods[index].price = (parseFloat(calculated_price) -
                                        parseFloat((calculated_price * this.form.courier_methods[index]
                                            .percentage) / 100)).toFixed(2);
                                } else {
                                    this.form.courier_methods[index].price = calculated_price;
                                }
                                this.form.courier_methods[index].calcprice = this.form.courier_methods[index].price;
                            }
                            return this.vehicleInfo = response.data.data
                        })
                        .catch(error => {
                            console.log(error);
                            this.prevStep();
                            return this.errors = 'Voertuig niet beschikbaar in dit pakket'
                        })
                        .finally(() => this.loading = false);
                }

                if (this.step == 3) {
                    if (!this.form.selectd_day) {
                        this.errors = "Selecteer eerst de optie";
                        return false;
                    }
                }

                if (this.step == 4) {
                    if (!this.form.date) {
                        this.validationErrors['date'] = 'Datum is verplicht';
                    }
                    if (!this.form.time) {
                        this.validationErrors['time'] = 'Datum is verplicht';
                    }
                    if (!this.form.pickupaddress.FirstName) {
                        this.validationErrors['p_FirstName'] = 'Het veld Ophaaladres voornaam is verplicht';
                    }
                    if (!this.form.pickupaddress.LastName) {
                        this.validationErrors['p_LastName'] = 'Het veld Ophaaladres achternaam is verplicht';
                    }
                    if (!this.form.pickupaddress.PhoneNumber) {
                        this.validationErrors['p_PhoneNumber'] = 'Het telefoonnummer voor het Ophaaladres is verplicht.';
                    }
                    if (!this.form.pickupaddress.CompanyName) {
                        this.validationErrors['p_CompanyName'] = 'Ophaaladres bedrijfsnaam veld is verplicht.';
                    }
                    if (!this.form.pickupaddress.email) {
                        this.validationErrors['p_email'] = 'E-mailveld voor Ophaaladres is verplicht';
                    }
                    if (!this.form.pickupaddress.Address) {
                        this.validationErrors['p_Address'] = 'Ophaaladres adresveld is verplicht.';
                    }
                    if (!this.form.pickupaddress.ZipCode) {
                        this.validationErrors['p_ZipCode'] = 'Ophaaladres postcode veld is verplicht.';
                    }
                    if (!this.form.pickupaddress.City) {
                        this.validationErrors['p_City'] = 'Ophaaladres stad veld is verplicht.';
                    }
                    if (!this.form.pickupaddress.Country) {
                        this.validationErrors['p_Country'] = 'Ophaaladres provincie is verplicht';
                    }

                    if(this.form.is_billing_address_same == 'yes'){
                        if (!this.form.factuur.FirstName) {
                            this.validationErrors['f_FirstName'] = 'Het veld Ophaaladres voornaam is verplicht';
                        }
                        if (!this.form.factuur.LastName) {
                            this.validationErrors['f_LastName'] = 'Het veld Ophaaladres achternaam is verplicht';
                        }
                        if (!this.form.factuur.PhoneNumber) {
                            this.validationErrors['f_PhoneNumber'] = 'Het telefoonnummer voor het Ophaaladres is verplicht.';
                        }
                        if (!this.form.factuur.CompanyName) {
                            this.validationErrors['f_CompanyName'] = 'Ophaaladres bedrijfsnaam veld is verplicht.';
                        }
                        if (!this.form.factuur.email) {
                            this.validationErrors['f_email'] = 'E-mailveld voor Ophaaladres is verplicht';
                        }
                        if (!this.form.factuur.Address) {
                            this.validationErrors['f_Address'] = 'Ophaaladres adresveld is verplicht.';
                        }
                        if (!this.form.factuur.ZipCode) {
                            this.validationErrors['f_ZipCode'] = 'Ophaaladres postcode veld is verplicht.';
                        }
                        if (!this.form.factuur.City) {
                            this.validationErrors['f_City'] = 'Ophaaladres stad veld is verplicht.';
                        }
                        if (!this.form.factuur.Country) {
                            this.validationErrors['f_Country'] = 'Ophaaladres provincie is verplicht';
                        }                        
                    }

                    if (!this.form.deliveryaddress.FirstName) {
                        this.validationErrors['d_FirstName'] = 'Het veld Afleveradres voornaam is verplicht.';
                    }
                    if (!this.form.deliveryaddress.LastName) {
                        this.validationErrors['d_LastName'] = 'Het veld Afleveradres achternaam is verplicht.';
                    }
                    if (!this.form.deliveryaddress.PhoneNumber) {
                        this.validationErrors['d_PhoneNumber'] = 'Het telefoonnummer voor het afleveradres is verplicht.';
                    }
                    if (!this.form.deliveryaddress.CompanyName) {
                        this.validationErrors['d_CompanyName'] = 'Bezorgadres bedrijfsnaam veld is verplicht';
                    }
                    if (!this.form.deliveryaddress.Email) {
                        this.validationErrors['d_Email'] = 'E-mailveld voor afleveradres is verplicht.';
                    }
                    if (!this.form.deliveryaddress.Address) {
                        this.validationErrors['d_Address'] = 'Afleveradres adresveld is verplicht.';
                    }
                    if (!this.form.deliveryaddress.ZipCode) {
                        this.validationErrors['d_ZipCode'] = 'Afleveradres postcode veld is verplicht.';
                    }
                    if (!this.form.deliveryaddress.City) {
                        this.validationErrors['d_City'] = 'Afleveradres stad veld is verplicht';
                    }
                    if (!this.form.deliveryaddress.Country) {
                        this.validationErrors['d_Country'] = 'Afleverings provincie is verplicht.';
                    }
                    if(this.isValid() > 0) return false;
                }

                if (this.step == 5) {
                    this.validateTerms();
                    if(this.isValid() > 0) return false;
                    this.formsubmit();
                }

                this.errors = null;
                this.step++;
            },
            prevStep: function () {
                this.step--;
                console.log(this.step);
                if (this.step == 1) {
                    this.errorForm = {
                        pickup:{
                            address: null,
                            addressTemp: null
                        },
                        delivery:{
                            address: null,
                            addressTemp: null
                        },
                    }
                    this.form.location_from = null;
                    this.form.location_to = null;
                    this.form.pickupaddress.Address = null;
                    this.form.deliveryaddress.Address = null;
                    this.form.distance = null;
                    this.errors = null;
                    this.loadAddress();
                }
            },
            updateDimentions: function (event) {
                this.dimensionErrors = {};
                this.form.dimensions = [{
                    length: null,
                    width: null,
                    height: null,
                    weight: null,
                    number: 1,
                    desc: null
                }];

                for (this.itration = 0; this.itration < event.target.value; this.itration++) {
                    this.dimensionErrors[this.itration] = {};
                }

                for (this.itration = 0; this.itration < event.target.value - 1; this.itration++) {
                    console.log(this.itration);
                    this.form.dimensions.push({
                        length: null,
                        width: null,
                        height: null,
                        weight: null,
                        number: 1,
                        desc: null
                    });
                }
                for (this.itration = 0; this.itration < event.target.value; this.itration++) {
                    this.dimensionErrors[this.itration] = {};
                    if(!this.form.dimensions[this.itration]['width']){
                      this.dimensionErrors[this.itration]['width'] = 'width '+this.itration+' is required';
                    }
                    if(!this.form.dimensions[this.itration]['length']){
                      this.dimensionErrors[this.itration]['length'] = 'length '+this.itration+' is required';
                    }
                    if(!this.form.dimensions[this.itration]['height']){
                      this.dimensionErrors[this.itration]['height'] = 'height '+this.itration+' is required';
                    }
                    if(!this.form.dimensions[this.itration]['weight']){
                      this.dimensionErrors[this.itration]['weight'] = 'weight '+this.itration+' is required';
                    }
                    if(!this.form.dimensions[this.itration]['number']){
                      this.dimensionErrors[this.itration]['number'] = 'number '+this.itration+' is required';
                    }
                }
            },
            selectVehicle: function (index) {
                if (index != this.form.selectd_index) {
                    this.errors = "Selecteer een geldige optie";
                    return false;
                }
                this.form.selectd_courier_method = index;
                this.form.selected_vehicle = this.vehicleInfo.id;
                this.nextStep();
                console.log(index);
            },
            formsubmit: function () {
                axios
                    .post('api/v1/save/quote', this.form)
                    .then(response => {
                        console.log(response.data);
                        // this.nextStep();
                        this.results = response.data.data;
                        this.success = response.data.message;
                        console.log(response.data.data);
                    })
                    .catch(error => {
                        console.log(error)
                        this.prevStep();
                        return this.errors = 'Controleer de validatiefouten!';
                        this.error = true
                    })
                    .finally(() => this.loading = false)
            },
            selectDays: function (inde1x, event, percentage) {
                for (var index in this.form.courier_methods) {
                    if (index == inde1x) {
                        this.form.service[index] = event.target.value;
                    } else {
                        this.form.service[index] = null
                    }
                }
                var selectedProduct = this.form.courier_methods[inde1x];
                var calculated_price = this.vehicleInfo.calculated_price;
                var optionPrice = parseFloat(calculated_price) - parseFloat((calculated_price * selectedProduct
                    .percentage) / 100);
                var optionPricePercentage = (optionPrice * percentage) / 100;
                this.form.courier_methods[inde1x].calcprice = (optionPrice + optionPricePercentage).toFixed(2);
                this.form.courier_methods[inde1x].price = ((optionPrice + optionPricePercentage) * this.form.courier_methods[inde1x].discount).toFixed(2);
                this.form.price = this.form.courier_methods[inde1x].price
                this.form.pricewithtax = parseFloat(parseFloat(this.form.price) + parseFloat(this.form.price * 21) /
                    100).toFixed(2);
                this.form.selectd_day = event.target.value;
                this.form.selectd_index = inde1x;
            },
            selectDiscount: function ( inde1x, event) {
                for (var index in this.form.courier_methods) {
                    if (index == inde1x) {
                        if (event.target.checked) {
                            this.form.courier_methods[index].discount = 0.7;
                        } else {
                            this.form.courier_methods[index].discount = 1;
                        }
                    } else {
                        this.form.courier_methods[index].discount = 1;
                    }
                }
                this.form.courier_methods[inde1x].price = this.form.courier_methods[inde1x].calcprice;
                this.form.courier_methods[inde1x].price = (this.form.courier_methods[inde1x].price * this.form.courier_methods[inde1x].discount).toFixed(2);
                this.form.price = this.form.courier_methods[inde1x].price
                this.form.pricewithtax = parseFloat(parseFloat(this.form.price) + parseFloat(this.form.price * 21) /
                    100).toFixed(2);
                this.form.selectd_day = event.target.value;
                this.form.selectd_index = inde1x;
            },
            calculateDistance: function () {
                if (this.geometryData.geometry1 && this.geometryData.geometry2) {
                    const data = {
                        lat1: this.geometryData.geometry1.lat,
                        lat2: this.geometryData.geometry2.lat,
                        lon1: this.geometryData.geometry1.lng,
                        lon2: this.geometryData.geometry2.lng,
                        from: this.form.location_from,
                        to: this.form.location_to
                    }
                    axios
                        .post('api/v1/calculate/distance', data)
                        .then(response => {
                            this.form.distance = response.data;
                        })
                        .catch(error => {
                            console.log(error)
                            this.error = true
                        })
                        .finally(() => this.loading = false)
                }
            },
            loadAddress: function () {
                var componentForm = {
                    route: 'short_name',
                    street_number: 'short_name',
                    locality: 'long_name',
                    country: 'short_name',
                    postal_code: 'short_name',
                    postal_code_prefix: 'short_name'
                };
                let pickupAddressData = [];
                let deliveryAddressData = [];
                var fromAddress = new google.maps.places.Autocomplete(document.getElementById('fromAddress'));
                fromAddress.addListener("place_changed", () => {
                    const place = fromAddress.getPlace();                    
                    this.form.location_from = place.formatted_address;
                    this.geometryData.geometry1 = {
                        lat: place.geometry.location.lat(),
                        lng: place.geometry.location.lng(),
                    }
                    this.calculateDistance();
                    pickupAddressData['formatted_address'] = place.formatted_address;
                    for (var i = 0; i < place.address_components.length; i++) {
                        var addressType = place.address_components[i].types[0];
                        if (componentForm[addressType]) {
                            var val = place.address_components[i][componentForm[addressType]];
                            pickupAddressData[addressType] = val;
                        }
                    }

                    if (pickupAddressData.route !== undefined) {
                        this.form.pickupaddress.Address = pickupAddressData.route;
                        this.errorForm.pickup.addressTemp = this.form.pickupaddress.Address;
                    }
                    if (pickupAddressData.street_number !== undefined) {
                        this.form.pickupaddress.Address = (this.form.pickupaddress.Address + " " + pickupAddressData.street_number).trim();
                        this.form.pickupaddress.street_number = pickupAddressData.street_number;
                    }                    
                    this.errorForm.pickup.addressTemp = this.form.pickupaddress.Address.trim();

                    if (pickupAddressData.postal_code !== undefined) {
                        this.form.pickupaddress.ZipCode = pickupAddressData.postal_code;
                    } else {
                        if (pickupAddressData.postal_code_prefix !== undefined) {
                            this.form.pickupaddress.ZipCode = pickupAddressData.postal_code_prefix;
                        }
                    }
                    if (pickupAddressData.locality !== undefined) {
                        this.form.pickupaddress.City = pickupAddressData.locality;
                    }
                    this.form.pickupaddress.Country = pickupAddressData.country
                });

                var toAddress = new google.maps.places.Autocomplete(document.getElementById('toAddress'));
                toAddress.addListener("place_changed", () => {
                    const place = toAddress.getPlace();
                    this.form.location_to = place.formatted_address;
                    this.geometryData.geometry2 = {
                        lat: place.geometry.location.lat(),
                        lng: place.geometry.location.lng(),
                    }
                    this.calculateDistance()

                    deliveryAddressData['formatted_address'] = place.formatted_address;
                    for (var i = 0; i < place.address_components.length; i++) {
                        var addressType = place.address_components[i].types[0];
                        if (componentForm[addressType]) {
                            var val = place.address_components[i][componentForm[addressType]];
                            deliveryAddressData[addressType] = val;
                        }
                    }
                    if (deliveryAddressData.route !== undefined) {
                        this.form.deliveryaddress.Address = deliveryAddressData.route;
                        this.errorForm.delivery.addressTemp = this.form.deliveryaddress.Address;
                    }
                    if (deliveryAddressData.street_number !== undefined) {
                        this.form.deliveryaddress.Address = (this.form.deliveryaddress.Address + " " + deliveryAddressData.street_number).trim();
                        this.form.deliveryaddress.street_number = deliveryAddressData.street_number;
                    }                    
                    this.errorForm.delivery.addressTemp = this.form.deliveryaddress.Address.trim();
                    
                    if (deliveryAddressData.postal_code !== undefined) {
                        this.form.deliveryaddress.ZipCode = deliveryAddressData.postal_code
                    } else {
                        if (deliveryAddressData.postal_code_prefix !== undefined) {
                            this.form.deliveryaddress.ZipCode = deliveryAddressData.postal_code_prefix;
                        }
                    }

                    if (deliveryAddressData.locality !== undefined) {
                        this.form.deliveryaddress.City = deliveryAddressData.locality;
                    }
                    this.form.deliveryaddress.Country = deliveryAddressData.country

                });

                fromAddress.setComponentRestrictions({
                    'country': ['nl', 'be', 'lux']
                });
                toAddress.setComponentRestrictions({
                    'country': ['nl', 'be', 'lux']
                });

                for (var index in this.form.courier_methods) {
                    this.form.service[index] = null;
                }
            },
            getTerms(){
                 axios
                .get('api/v1/get_conditions')
                .then(response => {
                    if(response.data.success){
                        this.termspopupcontent = response.data.results;
                    }
                })
                .catch(error => {

                })
                .finally(() => this.loading = false)
            }
        },
        mounted() {
            this.loadAddress();
            this.getTerms();
            for (var index in this.form.dimensions) { 
                this.dimensionErrors[index] = {};
            }
        }
    }

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    body {
        background: #f8f9fa !important;
    }

    .first_step {
        display: none;
    }

    .padding-y {
        padding-top: 40px;
        padding-bottom: 40px;
    }

    .bg {
        background-color: #f8f9fa;
    }

    .container {
        max-width: 1000px;
        margin: 0 auto;
        width: 100%;
    }

    .heading {
        /* max-width: 600px; */
        /* width: 100%; */
        margin: 0 auto;
        margin-left: 40px;
    }

    .title {
        font-size: 20px;
        color: #fff;
        text-transform: uppercase;
        background-color: #a9a8a6;
        padding: 13px 30px 13px;
        border-radius: 10px 10px 0px 0px;
        font-weight: 600;
        text-align: center;
        margin-bottom: 0;
    }

    .mod-form {
        background-color: #fcedf2;
        padding: 20px 15px;
        border-radius: 10px 10px 10px 10px;
        /* display: -webkit-box; */
        display: -ms-flexbox;
        /* display: flex;*/
    }

    .mod-form p {
        color: #fff;
        text-transform: uppercase;
        padding-bottom: 20px;
        font-weight: bold;
        font-size: 20px;
        margin: 0 0 1rem;
    }

    .input-text {
        padding: 26px 14px 14px 10px;
        border-color: transparent;
        border-radius: 6px;
        box-sizing: border-box;
        margin-right: 2vw;
        font-size: 20px;
        font-weight: 700;
        color: #e22f66;
    }

    #progressbar {
        margin-bottom: 30px;
        overflow: hidden;
        color: lightgrey
    }

    #progressbar .active {
        color: #e22f66
    }

    #progressbar li {
        list-style-type: none;
        font-size: 15px;
        width: 16%;
        float: left;
        position: relative;
        font-weight: 400
    }

    #progressbar #step1:before {
        content: "1"
    }

    #progressbar #step2:before {
        content: "2"
    }

    #progressbar #step3:before {
        content: "3"
    }

    #progressbar #step4:before {
        content: "4"
    }

    #progressbar #step5:before {
        content: "5"
    }

    #progressbar #step6:before {
        content: "6"
    }

    #progressbar li:before {
        width: 50px;
        height: 50px;
        line-height: 45px;
        display: block;
        font-size: 20px;
        color: #ffffff;
        background: lightgray;
        border-radius: 50%;
        margin: 0 auto 10px auto;
        padding: 2px
    }

    #progressbar li:after {
        content: '';
        width: 100%;
        height: 2px;
        background: lightgray;
        position: absolute;
        left: 0;
        top: 25px;
        z-index: -1
    }

    #progressbar li.active:before,
    #progressbar li.active:after {
        background: #e22f66
    }

    .progress {
        height: 20px
    }

    .progress-bar {
        background-color: #e22f66
    }

    .btn-primary {
        color: #fff;
        background-color: #e22f66;
        border-color: #e22f66;
    }

    .btn-check:focus+.btn-primary,
    .btn-primary:focus {
        color: #fff;
        background-color: #e22f66;
        border-color: #e22f66;
        -webkit-box-shadow: 0 0 0 0.25rem rgb(49 132 253 / 50%);
        box-shadow: 0 0 0 0.25rem rgb(49 132 253 / 50%);
    }

    .package_row:last-child {
        border-bottom: none;
    }

    .package_row {
        margin-bottom: 20px;
        border-bottom: 1px solid #bbb;
        padding: 20px 10px;
        background: #f2f2f2;
    }

    .row_corieor {
        border: 1px solid #ccc;
        margin-bottom: 6px;
        padding: 10px 4px;
    }

    .req {
        color: red;
    }

    .bcolor {
        border: 1px solid #ece8e8;
    }

    .theading {
        color: black;
        font-weight: 700;
        font-size: large;
    }

    .text-center {
        text-align: center !important;
        margin-top: 10px;
        margin-bottom: 10px;
    }

    #loading {
        position: fixed;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        opacity: 0.7;
        background-color: #fff;
        z-index: 99;
    }

    #loading-image {
        z-index: 100;
    }

	.errors {
		display: block !important;;
	}
    .invalid{
            width: 100%;
            margin-top: 0.25rem;
            font-size: 80%;
            color: #dc3545;
    }
    .row.no-gutters.row_corieor .col-md-5 {
        padding: 0px 15px 15px 15px;
    }
</style>
